.bottom {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin: 4rem auto;

    @media (max-width: 475px) {
      margin: 1rem auto;
    }

    button {
      @media (max-width: 475px) {
        margin-top: 0.75rem;
        margin-bottom: 0.75rem;
      }
    }
  }

  &__title {
    font-size: 6rem;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 4px;
    text-align: center;

    @media (max-width: 475px) {
      font-size: 3.3rem;
    }
  }

  &__link-container {
    display: flex;
    width: 100%;
    max-width: 45rem;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0;

    @media (max-width: 475px) {
      justify-content: center;
    }
  }

  &__link,
  &__link:visited {
    letter-spacing: 2px;
    text-align: center;
    text-decoration: none;
    color: black;
    font-size: 1.8rem;
    text-transform: uppercase;
    border: none;
    background-color: transparent;

    @media (max-width: 475px) {
      font-size: 1.4rem;
      margin: 0 1.5rem;
    }

    @media (max-width: 300px) {
      margin: 0 0.5rem;
    }
  }

  &__link:hover,
  &__link:focus {
    outline: none;
    cursor: pointer;
    color: rgb(255, 0, 183);
  }
}
