@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,400;1,700;1,800&display=swap');

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.6;
}

.react-responsive-modal-modal {
  padding: 4rem;
  border-radius: 5px;

  @media (max-width: 768px) {
    width: 90%;
  }
  @media (max-width: 700px) {
    width: 95%;
    padding: 2rem;
  }
  @media (max-width: 475px) {
    width: 100%;
  }
}

//
//
// Styles for the Data and About Pages
//
//
.secondary {
  padding: 0 1rem 3rem 1rem;

  // SET WIDTHS AND MARGINS
  // Can't set on a parent because the graphs are a different width
  main p,
  .section__heading {
    max-width: 65rem;
    margin-left: auto;
    margin-right: auto;
  }

  .section__heading {
    margin-top: 4rem;
    margin-bottom: 2rem;
    font-size: 3rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    transition: text-shadow 0.4s;

    &:hover {
      text-shadow: 2px 2px 0 rgb(255, 0, 183);
    }

    @media (max-width: 475px) {
      font-size: 2rem;
    }
  }

  main p {
    margin-bottom: 2rem;
    line-height: 1.8;
    font-size: 1.8rem;

    @media (max-width: 475px) {
      font-size: 1.6rem;
    }

    a,
    a:visited {
      color: rgb(255, 0, 183);
      text-decoration: none;

      &:hover {
        color: rgb(199, 1, 143);
      }
    }
  }
}
