.percentiles {
  &__container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__container > .chart {
    max-width: 45rem;
    margin: 1rem;
  }

  &__single {
    padding: 2rem;
  }

  &__graph {
    position: relative;
    padding-bottom: 100%;
    background-image: url('../../shared/img/grid-only.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
  }

  &__arrows {
    position: absolute;
    overflow: visible;
  }

  &__arrow-label {
    font-size: 1.8rem;
  }
}
