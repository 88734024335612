.directions {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 50vh;

  //text-transform: uppercase;
  font-size: 2rem;
  text-align: center;
  line-height: 1.2;
  font-weight: 600;

  border: 3px solid black;
  border-radius: 0px;
  padding: 2.5rem;
  background-color: white;
  box-shadow: 6px 6px 8px rgba(0, 0, 0, 0.5);

  opacity: 1;
  transition: opacity 0.3s;

  @media (max-width: 700px) {
    font-size: 1.6rem;
  }

  @media (max-width: 475px) {
    font-size: 1.3rem;
  }

  &--hidden {
    opacity: 0;
  }
}
