.modal {
  &__btn-group {
    display: flex;

    @media (max-width: 1000px) {
      flex-direction: column;
    }

    .button {
      @media (max-width: 1000px) {
        margin: 10px auto;
      }
    }
  }
}
