.Toaster {
  &__alert_close {
    font-size: 2rem;
  }
}

.toast__msg {
  text-align: left;
  font-size: 1.8rem;
  padding: 1rem;

  p {
    margin: 1rem 0;
  }
}
