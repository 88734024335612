.fruit {
  position: absolute;
  z-index: 10;
  transition: all 0.5s ease-in-out;

  &:hover {
    z-index: 100;
  }

  &__img {
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      transform: scale(1.2);
    }
  }

  &__label {
    opacity: 0;
    position: absolute;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 1.6rem;
    text-align: center;
    text-shadow: 2px 2px 2px white, -2px -2px 2px white;
    width: 200px;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    transition: opacity 0.2s;
    backface-visibility: hidden;

    @media (max-width: 450px) {
      display: none;
    }
  }

  &__img:hover + &__label {
    opacity: 1;
  }

  &--drag {
    transition: transform 0s;
  }
}

// Fruit is currently being dragged
.react-draggable-dragging {
  z-index: 100;
}

.react-draggable-dragging .fruit__img {
  // The translate here should match the yOffsetOnHover in DragGuides.js
  transform: translateY(-5px) scale(1.6);
  filter: drop-shadow(0px 15px 2px rgba(20, 20, 20, 0.4));

  // Fruit has been dragged outside of the graph area
  &--off-graph {
    transform: translateY(-3px) scale(0.5) rotate(380deg);
    filter: grayscale(70%);
  }
}

.react-draggable-dragging .fruit__img + .fruit__label {
  opacity: 0;
}
