.matrix {
  position: relative;

  &__graph {
    width: 100%;
    max-width: 65vh;
    margin: 0 auto;
    background-image: url('../shared/img/axis.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;

    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
  }
}
