@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,400;1,700;1,800&display=swap);
.button{color:black;width:33rem;max-width:100%;text-transform:uppercase;font-size:1.8rem;letter-spacing:1px;border:3px solid black;padding:1.5rem 0;margin:10px;font-family:inherit;background:linear-gradient(to top, white 50%, black 50%);background-size:100% 300%;background-position:left bottom;transition:all 0.3s ease-out}@media (max-width: 768px){.button{font-size:1.7rem;width:36rem;letter-spacing:1px;padding:1rem 0}}@media (max-width: 475px){.button{border-width:2px;padding:0.75rem 0}}@media (max-width: 380px){.button{font-size:1.3rem;width:100%}}.button:hover:not(.button--disabled){background-color:black;color:white;background-position:left top;box-shadow:3px 3px 6px grey;outline:none;transform:translateY(-2px)}.button:focus{box-shadow:2px 2px 10px #ff00b7,-2px -2px 10px #ff00b7}.button:active:not(.button--disabled){transform:translateY(3px)}.button--disabled{color:darkgray;border:3px solid darkgray}

.modal__title{text-transform:uppercase;letter-spacing:1px;margin:3rem 0;line-height:1.2;font-size:2.5rem}.modal__subtitle{margin:2rem 0;font-size:2.2rem}.modal__img{display:block;width:100%;height:auto;margin:2rem 0}@media (max-width: 475px){.modal__body{font-size:1.4rem}}.modal__body p:not(:last-child){margin-bottom:2rem}.modal__body a,.modal__body a:visited{text-decoration:none;color:#ff00b7}.modal__body a:hover,.modal__body a:visited:hover{color:#c7018f}.submit-modal-btn-container{margin-top:3rem;display:flex;justify-content:space-around;flex-wrap:wrap}.headshake{-webkit-animation:1.2s headshake 2s ease-in-out;animation:1.2s headshake 2s ease-in-out;-webkit-animation-iteration-count:4;animation-iteration-count:4}@-webkit-keyframes headshake{0%{transform:translateX(0)}6%{transform:translateX(-6px) rotateX(-9deg) rotateZ(1deg)}18%{transform:translateX(5px) rotateX(7deg) rotateZ(-1deg)}31%{transform:translateX(-3px) rotateX(-5deg) rotateZ(1deg)}43%{transform:translateX(2px) rotateX(3deg) rotateZ(-1deg)}50%{transform:translateX(0)}}@keyframes headshake{0%{transform:translateX(0)}6%{transform:translateX(-6px) rotateX(-9deg) rotateZ(1deg)}18%{transform:translateX(5px) rotateX(7deg) rotateZ(-1deg)}31%{transform:translateX(-3px) rotateX(-5deg) rotateZ(1deg)}43%{transform:translateX(2px) rotateX(3deg) rotateZ(-1deg)}50%{transform:translateX(0)}}

.modal__btn-group{display:flex}@media (max-width: 1000px){.modal__btn-group{flex-direction:column}}@media (max-width: 1000px){.modal__btn-group .button{margin:10px auto}}

.header{margin:3rem 0 3rem 0;text-align:center}.header__title{font-size:8rem;line-height:1;text-transform:uppercase;letter-spacing:8px;color:black;text-decoration:none}@media (max-width: 700px){.header__title{font-size:5.3rem;letter-spacing:5px}}@media (max-width: 475px){.header__title{font-size:4rem;letter-spacing:4px}}.header__title a,.header__title a:visited{text-decoration:none;color:black}.header__subtitle{font-size:1.8rem;line-height:1;margin:1rem 0}@media (max-width: 700px){.header__subtitle{font-size:1.4rem}}.header .button{margin:4rem auto;display:block}@media (max-width: 475px){.header .button{font-size:1.4rem;border-width:1.5px}}

.directions{position:absolute;top:40%;left:50%;transform:translate(-50%, -50%);width:80%;max-width:50vh;font-size:2rem;text-align:center;line-height:1.2;font-weight:600;border:3px solid black;border-radius:0px;padding:2.5rem;background-color:white;box-shadow:6px 6px 8px rgba(0,0,0,0.5);opacity:1;transition:opacity 0.3s}@media (max-width: 700px){.directions{font-size:1.6rem}}@media (max-width: 475px){.directions{font-size:1.3rem}}.directions--hidden{opacity:0}

.drag{z-index:2;position:absolute}

.arrow{position:absolute;opacity:0.4;z-index:-50}.ghost{position:absolute;-webkit-filter:opacity(60%) blur(3px);filter:opacity(60%) blur(3px);z-index:-100}

.fruit{position:absolute;z-index:10;transition:all 0.5s ease-in-out}.fruit:hover{z-index:100}.fruit__img{transition:all 0.3s;cursor:pointer}.fruit__img:hover{transform:scale(1.2)}.fruit__label{opacity:0;position:absolute;text-transform:uppercase;letter-spacing:1px;font-size:1.6rem;text-align:center;text-shadow:2px 2px 2px white, -2px -2px 2px white;width:200px;top:100%;left:50%;transform:translateX(-50%);transition:opacity 0.2s;-webkit-backface-visibility:hidden;backface-visibility:hidden}@media (max-width: 450px){.fruit__label{display:none}}.fruit__img:hover+.fruit__label{opacity:1}.fruit--drag{transition:transform 0s}.react-draggable-dragging{z-index:100}.react-draggable-dragging .fruit__img{transform:translateY(-5px) scale(1.6);-webkit-filter:drop-shadow(0px 15px 2px rgba(20,20,20,0.4));filter:drop-shadow(0px 15px 2px rgba(20,20,20,0.4))}.react-draggable-dragging .fruit__img--off-graph{transform:translateY(-3px) scale(0.5) rotate(380deg);-webkit-filter:grayscale(70%);filter:grayscale(70%)}.react-draggable-dragging .fruit__img+.fruit__label{opacity:0}

.Toaster__alert_close{font-size:2rem}.toast__msg{text-align:left;font-size:1.8rem;padding:1rem}.toast__msg p{margin:1rem 0}

.bottom{display:flex;flex-direction:column;align-items:center}.bottom__buttons{display:flex;flex-direction:row;justify-content:center;flex-wrap:wrap;margin:4rem auto}@media (max-width: 475px){.bottom__buttons{margin:1rem auto}}@media (max-width: 475px){.bottom__buttons button{margin-top:0.75rem;margin-bottom:0.75rem}}.bottom__title{font-size:6rem;line-height:1;text-transform:uppercase;letter-spacing:4px;text-align:center}@media (max-width: 475px){.bottom__title{font-size:3.3rem}}.bottom__link-container{display:flex;width:100%;max-width:45rem;justify-content:space-around;align-items:center;padding:10px 0}@media (max-width: 475px){.bottom__link-container{justify-content:center}}.bottom__link,.bottom__link:visited{letter-spacing:2px;text-align:center;text-decoration:none;color:black;font-size:1.8rem;text-transform:uppercase;border:none;background-color:transparent}@media (max-width: 475px){.bottom__link,.bottom__link:visited{font-size:1.4rem;margin:0 1.5rem}}@media (max-width: 300px){.bottom__link,.bottom__link:visited{margin:0 0.5rem}}.bottom__link:hover,.bottom__link:focus{outline:none;cursor:pointer;color:#ff00b7}

.matrix{position:relative}.matrix__graph{width:100%;max-width:65vh;margin:0 auto;background-image:url(/static/media/axis.428f679b.svg);background-position:center;background-repeat:no-repeat;background-size:auto}.matrix__graph:after{content:'';display:block;padding-bottom:100%}

.nav{border:4px solid #ff00b7;padding:4px;max-width:65rem;margin:3rem auto;text-align:center}@media (max-width: 475px){.nav{border-width:2px}}.nav__wrapper{padding:1rem;border:3px solid black}@media (max-width: 475px){.nav__wrapper{border-width:2px}}.nav__title{font-size:2.5rem;text-transform:uppercase;letter-spacing:2px;font-weight:bold}@media (max-width: 475px){.nav__title{font-size:2rem}}.nav__ul{list-style-type:none;line-height:2}.nav__li{font-size:2rem}@media (max-width: 475px){.nav__li{font-size:1.6rem}}.nav__link,.nav__link:visited{text-decoration:none;color:black}.nav__link:hover,.nav__link:focus{color:#ff00b7;outline:none}.nav__link:hover::before,.nav__link:focus::before{content:'{ ';color:black;outline:none}.nav__link:hover::after,.nav__link:focus::after{content:' }';color:black;outline:none}

.percentiles__container{display:flex;justify-content:center;flex-wrap:wrap}.percentiles__container>.chart{max-width:45rem;margin:1rem}.percentiles__single{padding:2rem}.percentiles__graph{position:relative;padding-bottom:100%;background-image:url(/static/media/grid-only.57c1fba8.svg);background-position:center;background-repeat:no-repeat;background-size:auto}.percentiles__arrows{position:absolute;overflow:visible}.percentiles__arrow-label{font-size:1.8rem}

.chart{border:4px solid black;padding:1rem 2rem 2rem 2rem;max-width:75rem;margin:3rem auto}@media (max-width: 475px){.chart{padding:0.5rem 1rem 1rem 1rem}}.chart__header{margin-bottom:2rem}.chart__title{text-transform:uppercase;letter-spacing:2px;font-size:2.8rem;line-height:1.2;margin-bottom:1rem}@media (max-width: 475px){.chart__title{font-size:2.2rem}}.chart__subtitle{color:darkgrey;font-size:1.7rem;line-height:1.4}@media (max-width: 475px){.chart__subtitle{font-size:1.4rem}}.chart__histogram{background-image:url(/static/media/axis.428f679b.svg);background-position:center;background-repeat:no-repeat;background-size:auto}.chart__fruit-img-in-title{position:relative;top:8px;display:inline-block;height:3.5rem;margin-right:1rem}.histograms{display:flex;flex-wrap:wrap;justify-content:center}.histograms>.chart{max-width:50rem;margin:1rem}.tick text{font-size:1.8rem;font-weight:bold;text-transform:uppercase;letter-spacing:1px;shape-rendering:crispEdges}.tick text.tick--small{font-size:1.4rem}

*{margin:0;padding:0}*,*::before,*::after{box-sizing:inherit}html{box-sizing:border-box;font-size:62.5%}body{font-family:'Open Sans', sans-serif;font-size:1.6rem;font-weight:400;line-height:1.6}.react-responsive-modal-modal{padding:4rem;border-radius:5px}@media (max-width: 768px){.react-responsive-modal-modal{width:90%}}@media (max-width: 700px){.react-responsive-modal-modal{width:95%;padding:2rem}}@media (max-width: 475px){.react-responsive-modal-modal{width:100%}}.secondary{padding:0 1rem 3rem 1rem}.secondary main p,.secondary .section__heading{max-width:65rem;margin-left:auto;margin-right:auto}.secondary .section__heading{margin-top:4rem;margin-bottom:2rem;font-size:3rem;text-transform:uppercase;letter-spacing:2px;transition:text-shadow 0.4s}.secondary .section__heading:hover{text-shadow:2px 2px 0 #ff00b7}@media (max-width: 475px){.secondary .section__heading{font-size:2rem}}.secondary main p{margin-bottom:2rem;line-height:1.8;font-size:1.8rem}@media (max-width: 475px){.secondary main p{font-size:1.6rem}}.secondary main p a,.secondary main p a:visited{color:#ff00b7;text-decoration:none}.secondary main p a:hover,.secondary main p a:visited:hover{color:#c7018f}

