.button {
  color: black;
  width: 33rem;
  max-width: 100%;
  text-transform: uppercase;
  font-size: 1.8rem;
  letter-spacing: 1px;
  border: 3px solid black;
  padding: 1.5rem 0;
  margin: 10px;
  font-family: inherit;
  background: linear-gradient(to top, white 50%, black 50%);
  background-size: 100% 300%;
  background-position: left bottom;
  transition: all 0.3s ease-out;

  @media (max-width: 768px) {
    font-size: 1.7rem;
    width: 36rem;
    letter-spacing: 1px;
    padding: 1rem 0;
  }

  @media (max-width: 475px) {
    border-width: 2px;
    padding: 0.75rem 0;
  }

  @media (max-width: 380px) {
    font-size: 1.3rem;
    width: 100%;
  }

  &:hover:not(&--disabled) {
    background-color: black;
    color: white;
    background-position: left top;
    box-shadow: 3px 3px 6px grey;
    outline: none;
    transform: translateY(-2px);
  }

  &:focus {
    box-shadow: 2px 2px 10px rgb(255, 0, 183), -2px -2px 10px rgb(255, 0, 183);
  }

  &:active:not(&--disabled) {
    transform: translateY(3px);
  }

  &--disabled {
    color: darkgray;
    border: 3px solid darkgray;
  }
}
