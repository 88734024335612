.nav {
  border: 4px solid rgb(255, 0, 183);
  padding: 4px;
  max-width: 65rem;
  margin: 3rem auto;
  text-align: center;

  @media (max-width: 475px) {
    border-width: 2px;
  }

  &__wrapper {
    padding: 1rem;
    border: 3px solid black;

    @media (max-width: 475px) {
      border-width: 2px;
    }
  }

  &__title {
    font-size: 2.5rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;

    @media (max-width: 475px) {
      font-size: 2rem;
    }
  }

  &__ul {
    list-style-type: none;
    line-height: 2;
  }

  &__li {
    font-size: 2rem;

    @media (max-width: 475px) {
      font-size: 1.6rem;
    }
  }

  &__link,
  &__link:visited {
    text-decoration: none;
    color: black;
  }

  &__link:hover,
  &__link:focus {
    color: rgb(255, 0, 183);
    outline: none;
  }

  &__link:hover::before,
  &__link:focus::before {
    content: '{ ';
    color: black;
    outline: none;
  }

  &__link:hover::after,
  &__link:focus::after {
    content: ' }';
    color: black;
    outline: none;
  }
}
